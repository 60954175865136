import axios from 'axios'

export const WC_API = 'wp-json/wc/v3'
export const BASE_API = 'wp-json/wp/v2'
export const AUTH_API = 'wp-json/jwt-auth/v1'
export const API = axios.create({
  baseURL: 'https://cms.beze.store'
})

API.interceptors.request
  .use(request => {
    const token = localStorage.getItem('token')
    if (token) {
      if (!request.url?.includes(WC_API)) {
        request.headers.Authorization = `Bearer ${token}`
      } else {
        request.headers['X-WP-TOKEN'] = `${token}`
      }
    }
    return request
  },
  error => {
    Promise.reject(error)
  })

API.interceptors.response
  .use(response => {
    return response
  },
  error => {
    if ((error?.response?.data?.data?.status === 403 || error?.response?.data?.data?.status === 401) && error?.response?.data?.code === 'jwt_auth_invalid_token') {
      localStorage.removeItem('token')
      localStorage.removeItem('user-data')
      location.reload()
    }
    return Promise.reject(error)
  })
