import { API, AUTH_API } from '@/api-config'
import { ILoginData } from '@/shared/models/auth'

export default {
  login(data: ILoginData): Promise<{ data: { token: string } }> {
    return API.post(`${AUTH_API}/token`, data)
  },

  checkUser(): Promise< {data: { code: string, data: { status: number } }} > {
    return API.post(`${AUTH_API}/token/validate`)
  }
}
