<template>
  <svg width="22" height="22" viewBox="0 0 22 22" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6087 0H9.08696C7.76888 0 6.69566 1.07322 6.69566 2.39129V8.13043C6.69566 8.39443 6.90994 8.60871 7.17394 8.60871C7.43794 8.60871 7.65222 8.39443 7.65222 8.13043V2.39129C7.65222 1.60023 8.29594 0.956508 9.087 0.956508H19.6088C20.3998 0.956508 21.0436 1.60023 21.0436 2.39129V19.6087C21.0436 20.3998 20.3998 21.0435 19.6088 21.0435H9.08696C8.2959 21.0435 7.65217 20.3998 7.65217 19.6087V13.8696C7.65217 13.6056 7.43789 13.3913 7.17389 13.3913C6.90989 13.3913 6.69562 13.6056 6.69562 13.8696V19.6087C6.69562 20.9268 7.76884 22 9.08691 22H19.6087C20.9268 22 22 20.9268 22 19.6087V2.39129C22 1.07322 20.9268 0 19.6087 0Z"/>
    <path d="M15.7826 10.5218H0.478276C0.214276 10.5218 0 10.736 0 11C0 11.264 0.214276 11.4783 0.478276 11.4783H15.7826C16.0466 11.4783 16.2609 11.264 16.2609 11C16.2609 10.736 16.0466 10.5218 15.7826 10.5218Z"/>
    <path d="M16.1203 10.6633L13.2507 7.79376C13.0642 7.60724 12.761 7.60724 12.5745 7.79376C12.3879 7.98028 12.3879 8.28352 12.5745 8.47004L15.1054 11.001L12.5745 13.532C12.3879 13.7185 12.3879 14.0217 12.5745 14.2082C12.6682 14.301 12.7906 14.3479 12.9131 14.3479C13.0355 14.3479 13.1579 14.301 13.2507 14.2092L16.1203 11.3397C16.3068 11.1531 16.3068 10.8499 16.1203 10.6633Z"/>
  </svg>
</template>

<script>
export default {
  name: 'LoginIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
