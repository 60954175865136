import Vue from 'vue'
import VueRouter, { Route, RouteConfig } from 'vue-router'
import DefaultView from '@/views/Default.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: DefaultView,
    children: [
      {
        path: '',
        meta: { transitionName: 'slide' },
        component: () => import('@/pages/FrontPage.vue')
      },
      {
        path: 'catalog',
        meta: { transitionName: 'slide' },
        component: () => import('@/views/Catalog.vue'),
        children: [
          {
            path: '',
            meta: { transitionName: 'slide', title: 'Каталог тортов' },
            component: () => import('@/pages/Catalog.vue')
          },
          {
            path: ':slug',
            meta: { transitionName: 'slide', title: 'Каталог тортов' },
            component: () => import('@/pages/CatalogSingle.vue')
          }
        ]
      },
      {
        path: 'catalog/:slug/:id',
        meta: {
          transitionName: 'zoom',
          ignoreScrollToTop: true,
          title: (route: Route) => `Товар №${route.params.id}`
        },
        beforeEnter: (to, from, next) => {
          if (from.name === null) {
            document.body.style.overflow = 'hidden'
          }
          next()
        },
        component: () => import('@/pages/ProductSingle.vue')
      },
      {
        path: 'cart',
        meta: { transitionName: 'slide', title: 'Корзина' },
        component: () => import('@/pages/Cart.vue')
      },
      {
        path: 'checkout',
        meta: { transitionName: 'slide', title: 'Оформление заказа' },
        component: () => import('@/pages/Checkout.vue'),
        beforeEnter: (to, from, next) => {
          store.getters.cart.length ? next() : next('/cart')
        }
      },
      {
        path: 'fillings',
        meta: { transitionName: 'slide', title: 'Начинки для тортов' },
        component: () => import('@/pages/Fillings.vue')
      },
      {
        path: 'account',
        meta: { transitionName: 'slide', title: 'Личный кабинет' },
        component: () => import('@/pages/Account.vue'),
        beforeEnter: (to, from, next) => {
          store.getters.userToken ? next() : next('/')
        }
      },
      {
        path: 'order/:id',
        meta: { transitionName: 'slide', title: 'Заказ успешно создан' },
        component: () => import('@/pages/Order.vue'),
        beforeEnter: (to, from, next) => {
          const yooOrderID = window.sessionStorage.getItem('yooOrderID') || ''
          to.query.orderID === yooOrderID ? next() : next('/')
        }
      },
      {
        path: '404',
        meta: { transitionName: 'slide', title: 'Страница не найдена' },
        component: () => import('@/pages/NotFound.vue')
      },
      {
        path: 'how-to',
        meta: { transitionName: 'slide', title: 'Как заказать' },
        props: { WpPageID: 571 },
        component: () => import('@/pages/TextPage.vue')
      },
      {
        path: 'delivery',
        meta: { transitionName: 'slide', title: 'Доставка' },
        props: { WpPageID: 573 },
        component: () => import('@/pages/TextPage.vue')
      },
      {
        path: 'registration',
        meta: { transitionName: 'slide', title: 'Регистрация' },
        component: () => import('@/pages/Registration.vue'),
        beforeEnter: (to, from, next) => {
          !store.getters.userToken ? next() : next('/account')
        }
      },
      {
        path: 'policy',
        meta: { transitionName: 'slide', title: 'Политика конфиденциальности' },
        component: () => import('@/pages/Policy.vue')
      },
      {
        path: 'reviews',
        meta: { transitionName: 'slide', title: 'Отзывы' },
        component: () => import('@/pages/Reviews.vue')
      },
      {
        path: 'special',
        meta: { transitionName: 'slide', title: 'Акции и спецпредложения' },
        component: () => import('@/pages/Special.vue')
      },
      {
        path: '*',
        meta: { transitionName: 'slide' },
        redirect: '/404'
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else if (!to.meta.ignoreScrollToTop) {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch('toggleMobileNav', false)
  document.title = to.meta.title
    ? `${typeof to.meta.title === 'string' ? to.meta.title : to.meta.title(to)} - Кондитерская-пекарня Безе`
    : 'Интернет-магазин тортов в Тюмени - Кондитерская-пекарня Безе'
  next()
})

export default router
