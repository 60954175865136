import { Commit } from 'vuex'
import API from '@/api/base'
import { CakeFilling } from '@/shared/models/fillings'

interface IState {
  fillings: Array<CakeFilling>
}

export default {
  state: (): IState => ({
    fillings: []
  }),
  mutations: {
    SET_FILLINGS(state: IState, fillings: Array<CakeFilling>): void {
      state.fillings = fillings
    }
  },
  actions: {
    getFillings({ commit }: { commit: Commit }): Promise<void> {
      return API.getCakeFillings().then(response => {
        commit('SET_FILLINGS', response.data)
        return Promise.resolve()
      })
    }
  },
  getters: {
    fillings(state: IState): Array<CakeFilling> {
      return state.fillings || []
    }
  }
}
