










































import Vue from 'vue'
import Component from 'vue-class-component'
import BezeButton from '@/components/common/BezeButton.vue'
import BezeInput from '@/components/common/BezeInput.vue'
import CloseIcon from '@/components/icons/CloseIcon'

@Component({
  components: {
    BezeButton,
    BezeInput,
    CloseIcon
  }
})
export default class LoginForm extends Vue {
  username = ''
  password = ''
  isBtnLoading = false

  get isFormCorrect(): boolean {
    return !!this.password && !!this.username
  }

  get isModalVisible(): boolean {
    return this.$store.getters.isLoginModalVisible
  }

  get authErrorMsg(): string {
    return this.$store.getters.authErrorMsg
  }

  login(): void {
    this.isBtnLoading = true
    const { username, password } = this
    this.$store.dispatch(
      'login',
      { username, password }
    ).then(() => {
      this.username = ''
      this.password = ''
      this.$store.dispatch('toggleModal', {
        open: false,
        modalName: 'loginModal'
      })
    }).finally(() => {
      this.isBtnLoading = false
    })
  }
}
