










import Vue from 'vue'
import Component from 'vue-class-component'
import TopPanel from '@/components/header/TopPanel.vue'
import Navigation from '@/components/common/Navigation.vue'
import UserBar from '@/components/header/UserBar.vue'

@Component({
  components: {
    TopPanel,
    Navigation,
    UserBar
  }
})
export default class Header extends Vue {
  navList: Array<{ link: string; name: string; isSubItem?: boolean; }> = [
    {
      link: '/catalog/wedding',
      name: 'Свадебные',
      isSubItem: true
    },
    {
      link: '/catalog/kids',
      name: 'Детские',
      isSubItem: true
    },
    {
      link: '/catalog/holiday',
      name: 'Праздничные',
      isSubItem: true
    },
    {
      link: '/catalog/regular',
      name: 'Фирменные',
      isSubItem: true
    },
    {
      link: '/catalog/desserts',
      name: 'Десерты'
    },
    {
      link: '/catalog/korporativnye-podarki',
      name: 'Корпоративные подарки'
    },
    {
      link: '/fillings',
      name: 'Начинки'
    },
    {
      link: '/catalog/gastroboksy',
      name: 'Гастробоксы'
    },
    {
      link: '/catalog/pirogi',
      name: 'Пироги'
    },
    {
      link: '/special',
      name: 'Акции'
    }
  ]
}
