<template>
  <svg width="18" height="18" viewBox="0 0 18 18" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.5625 18H14.4375C16.4017 18 18 16.4017 18 14.4375V3.5625C18 1.59825 16.4017 0 14.4375 0H3.5625C1.59825 0 0 1.59825 0 3.5625V14.4375C0 16.4017 1.59825 18 3.5625 18ZM1.125 3.5625C1.125 2.2185 2.2185 1.125 3.5625 1.125H14.4375C15.7815 1.125 16.875 2.2185 16.875 3.5625V14.4375C16.875 15.7815 15.7815 16.875 14.4375 16.875H3.5625C2.2185 16.875 1.125 15.7815 1.125 14.4375V3.5625Z"/>
    <path d="M9.00003 13.5975C11.535 13.5975 13.5975 11.535 13.5975 9.00003C13.5975 6.46503 11.535 4.40253 9.00003 4.40253C6.46503 4.40253 4.40253 6.46503 4.40253 9.00003C4.40253 11.535 6.46503 13.5975 9.00003 13.5975ZM9.00003 5.52753C10.9148 5.52753 12.4725 7.08528 12.4725 9.00003C12.4725 10.9148 10.9148 12.4725 9.00003 12.4725C7.08528 12.4725 5.52753 10.9148 5.52753 9.00003C5.52753 7.08528 7.08528 5.52753 9.00003 5.52753V5.52753Z"/>
    <path d="M13.7685 5.5215C14.508 5.5215 15.0652 4.9665 15.0652 4.23075C15.0652 3.4635 14.4787 2.94 13.7692 2.94C13.0297 2.94 12.4725 3.495 12.4725 4.23075C12.4725 4.9965 13.056 5.5215 13.7685 5.5215ZM13.9012 4.107C14.1382 4.31625 13.5975 4.5705 13.5975 4.2315C13.5975 4.029 13.8727 4.0815 13.9012 4.107Z"/>
  </svg>
</template>

<script>
export default {
  name: 'InstaIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
