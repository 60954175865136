
























import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
export default class BezeButton extends Vue {
  @Prop({ default: 'base' }) theme!: 'base' | 'muted' | 'ternary' | 'transparent' | 'accent' | 'accentSecondary'
  @Prop({ default: 'sm' }) size!: 'xs' | 'sm' | 'md'
  @Prop({ default: false }) asLink!: boolean
  @Prop({ default: false }) asIcon!: boolean
  @Prop({ default: false }) isDisabled!: boolean
  @Prop({ default: '' }) route!: string
  @Prop({ default: '' }) text!: string
}
