import { Commit } from 'vuex'
import { Data, IState, ModalData, MODALS } from '@/shared/models/modals'

export default {
  state: (): IState => ({
    isLoginModalVisible: false,
    isRequestModalVisible: false,
    data: null
  }),
  mutations: {
    TOGGLE_MODAL(state: IState, payload: ModalData): void {
      document.body.style.overflow = payload.open ? 'hidden' : ''
      state[MODALS[payload.modalName]] = payload.open
      state.data = payload.data || null
    }
  },
  actions: {
    toggleModal({ commit }: { commit: Commit }, payload: ModalData): void {
      commit('TOGGLE_MODAL', payload)
    }
  },
  getters: {
    isLoginModalVisible(state: IState): boolean {
      return state.isLoginModalVisible
    },
    isRequestModalVisible(state: IState): boolean {
      return state.isRequestModalVisible
    },
    modalData(state: IState): Data | null {
      return state.data
    }
  }
}
