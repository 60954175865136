import { Commit } from 'vuex'
import { ProductListItem } from '@/shared/models/cakes'
import { PromoCode } from '@/shared/models/orders'

interface IState {
  cart: Array<ProductListItem>;
  promoCode: PromoCode | null;
}

export default {
  state: (): IState => ({
    cart: JSON.parse(<string>localStorage.getItem('cart')) || [],
    promoCode: null
  }),
  mutations: {
    ADD_TO_CART(state: IState, cake: ProductListItem): void {
      const cakeIdx = state.cart.findIndex(curCake => curCake.id === cake.id)
      cake.quantity = 1
      if (cakeIdx !== -1) {
        state.cart.splice(cakeIdx, 1, cake)
      } else {
        state.cart.push(cake)
      }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    REMOVE_FROM_CART(state: IState, cakeId: number): void {
      const cakeIdx = state.cart.findIndex(cake => cake.id === cakeId)
      if (cakeIdx !== -1) {
        state.cart.splice(cakeIdx, 1)
      }
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    CLEAR_CART(state: IState): void {
      state.cart = []
      localStorage.removeItem('cart')
    },
    CHANGE_CAKE_QUANTITY(state: IState, config: { cakeId: number, newQuantity: number}): void {
      const cakeIdx = state.cart.findIndex(curCake => curCake.id === config.cakeId)
      if (cakeIdx !== -1 && +config.newQuantity > 0) {
        if (+config.newQuantity < +state.cart[cakeIdx].acf.cake_min_weight) return
        state.cart[cakeIdx][['desserts', 'korporativnye-podarki', 'gastroboksy'].includes(state.cart[cakeIdx].categories[0].slug) ? 'weight' : 'quantity'] = +config.newQuantity
        state.cart = [...state.cart]
      }
    },
    SET_PROMO_CODE(state: IState, code: PromoCode): void {
      state.promoCode = code
    }
  },
  actions: {
    addToCart({ commit }: { commit: Commit }, cake: ProductListItem): void {
      commit('ADD_TO_CART', cake)
    },
    removeFromCart({ commit }: { commit: Commit }, cakeId: number): void {
      commit('REMOVE_FROM_CART', cakeId)
    },
    clearCart({ commit }: { commit: Commit }): void {
      commit('CLEAR_CART')
    },
    changeCakeQuantity({ commit }: { commit: Commit }, config: { cakeId: number, newQuantity: number }): void {
      +config.newQuantity > 0 && commit('CHANGE_CAKE_QUANTITY', { ...config })
    },
    setPromoCode({ commit }: { commit: Commit }, code: PromoCode): void {
      commit('SET_PROMO_CODE', code)
    }
  },
  getters: {
    cart(state: IState): Array<ProductListItem> {
      return state.cart || []
    },
    promoCode(state: IState): PromoCode | null {
      return state.promoCode
    },
    hasVariableItem(state: IState): boolean {
      return state.cart.some(({ type }) => type === 'variable')
    }
  }
}
