



















import Vue from 'vue'
import Component from 'vue-class-component'
import Socials from '@/components/common/Socials.vue'
import { CONTACTS } from '@/shared/const'
import Navigation from '@/components/common/Navigation.vue'
import BottomPanel from '@/components/footer/BottomPanel.vue'
import YaMap from '@/components/common/YaMap.vue'
import Phone from '@/components/common/Phone.vue'

@Component({
  components: {
    Socials,
    Navigation,
    BottomPanel,
    YaMap,
    Phone
  }
})
export default class Footer extends Vue {
  navList: Array<{ link: string; name: string; }> = [
    {
      link: '/catalog',
      name: 'Каталог тортов'
    },
    {
      link: '/fillings',
      name: 'Начинки'
    },
    {
      link: '/how-to',
      name: 'Как заказать'
    },
    {
      link: '/delivery',
      name: 'Доставка'
    },
    {
      link: '/special',
      name: 'Акции и спецпредложения'
    }
  ]

  data(): Record<string, unknown> {
    return {
      CONTACTS
    }
  }
}
