<template>
  <svg width="21" height="21" viewBox="0 0 21 21" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.4439 15.0709H8.6171C7.90564 15.068 7.21643 14.8336 6.66309 14.4063C6.10974 13.979 5.72528 13.3842 5.57309 12.7201L3.02844 1.39608H0.556085C0.408602 1.39608 0.267159 1.3401 0.162873 1.24045C0.058587 1.1408 0 1.00564 0 0.864713C0 0.723785 0.058587 0.58863 0.162873 0.488979C0.267159 0.389328 0.408602 0.333344 0.556085 0.333344H3.47831C3.60554 0.333307 3.72894 0.374961 3.82791 0.451355C3.92688 0.527749 3.99545 0.634263 4.02216 0.753126L6.66079 12.4964C6.75874 12.9232 7.00594 13.3054 7.36165 13.58C7.71736 13.8547 8.16036 14.0053 8.61765 14.0071H20.4439C20.5914 14.0071 20.7328 14.0631 20.8371 14.1627C20.9414 14.2624 21 14.3975 21 14.5385C21 14.6794 20.9414 14.8145 20.8371 14.9142C20.7328 15.0138 20.5914 15.0698 20.4439 15.0698V15.0709Z"/>
    <path d="M17.7196 11.4172H5.72651C5.57902 11.4172 5.43758 11.3612 5.33329 11.2615C5.22901 11.1619 5.17042 11.0267 5.17042 10.8858C5.17042 10.7449 5.22901 10.6097 5.33329 10.5101C5.43758 10.4104 5.57902 10.3544 5.72651 10.3544H17.7196C17.9867 10.3558 18.2453 10.2653 18.4483 10.0994C18.6513 9.93354 18.785 9.70342 18.8251 9.4511L19.672 4.22348H4.11108C3.96359 4.22348 3.82215 4.16749 3.71787 4.06784C3.61358 3.96819 3.55499 3.83303 3.55499 3.69211C3.55499 3.55118 3.61358 3.41602 3.71787 3.31637C3.82215 3.21672 3.96359 3.16074 4.11108 3.16074H20.321C20.4012 3.16075 20.4804 3.17735 20.5533 3.20939C20.6262 3.24143 20.6909 3.28815 20.7431 3.34635C20.7953 3.40455 20.8337 3.47285 20.8557 3.54657C20.8776 3.62028 20.8826 3.69766 20.8704 3.77341L19.925 9.6137C19.8453 10.1173 19.5786 10.5766 19.1737 10.9077C18.7687 11.2389 18.2526 11.4197 17.7196 11.4172Z"/>
    <path d="M17.5595 20.3246C17.1102 20.3245 16.6711 20.1971 16.2976 19.9585C15.9241 19.7199 15.6331 19.3809 15.4612 18.9842C15.2894 18.5876 15.2445 18.1512 15.3322 17.7301C15.4199 17.3091 15.6363 16.9224 15.954 16.6189C16.2717 16.3153 16.6765 16.1087 17.1171 16.0249C17.5577 15.9412 18.0144 15.9843 18.4295 16.1486C18.8445 16.3129 19.1993 16.5911 19.4489 16.948C19.6985 17.305 19.8317 17.7246 19.8317 18.1539C19.8309 18.7295 19.5913 19.2813 19.1653 19.6882C18.7393 20.0952 18.1618 20.324 17.5595 20.3246ZM17.5595 17.0455C17.3302 17.0456 17.1061 17.1107 16.9155 17.2325C16.7249 17.3543 16.5764 17.5273 16.4887 17.7298C16.4011 17.9322 16.3782 18.155 16.423 18.3699C16.4678 18.5847 16.5782 18.7821 16.7404 18.937C16.9026 19.0919 17.1092 19.1973 17.3341 19.2401C17.559 19.2828 17.7921 19.2608 18.0039 19.1769C18.2157 19.093 18.3968 18.951 18.5241 18.7689C18.6515 18.5867 18.7195 18.3725 18.7195 18.1534C18.7191 17.8596 18.5967 17.578 18.3792 17.3703C18.1618 17.1626 17.867 17.0458 17.5595 17.0455Z"/>
    <path d="M9.80102 20.3246C9.35174 20.3246 8.91255 20.1973 8.53899 19.9587C8.16543 19.7202 7.87427 19.3812 7.70234 18.9846C7.5304 18.588 7.48542 18.1515 7.57307 17.7304C7.66072 17.3094 7.87707 16.9226 8.19476 16.619C8.51245 16.3155 8.91721 16.1087 9.35786 16.025C9.79851 15.9412 10.2552 15.9842 10.6703 16.1485C11.0854 16.3128 11.4402 16.591 11.6898 16.948C11.9394 17.3049 12.0726 17.7246 12.0726 18.1539C12.0719 18.7294 11.8323 19.2811 11.4065 19.688C10.9806 20.0949 10.4033 20.3239 9.80102 20.3246ZM9.80102 17.0455C9.57171 17.0455 9.34755 17.1105 9.15688 17.2322C8.96621 17.3539 8.8176 17.527 8.72985 17.7294C8.64209 17.9319 8.61913 18.1546 8.66387 18.3695C8.7086 18.5844 8.81903 18.7819 8.98118 18.9368C9.14333 19.0917 9.34992 19.1973 9.57483 19.24C9.79974 19.2828 10.0329 19.2608 10.2447 19.177C10.4566 19.0931 10.6377 18.9511 10.7651 18.7689C10.8925 18.5867 10.9605 18.3725 10.9605 18.1534C10.96 17.8597 10.8377 17.5781 10.6204 17.3704C10.403 17.1628 10.1084 17.0459 9.80102 17.0455Z"/>
  </svg>
</template>

<script>
export default {
  name: 'CartIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
