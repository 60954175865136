import { API, BASE_API, WC_API } from '@/api-config'
import { CakeCategoryItem, ProductListParams, ProductListItem } from '@/shared/models/cakes'

export default {
  getProducts(): Promise<{ data: Array<unknown> }> {
    return API.get(`${WC_API}/products`)
  },

  getCakeCategories(): Promise<{ data: Array<CakeCategoryItem> }> {
    return API.get(`${WC_API}/products/categories`)
  },

  getCakes(params: ProductListParams): Promise<{ data: Array<ProductListItem>, headers: Record<string, unknown> }> {
    return API.get(`${WC_API}/products`, { params })
  },

  getProductById(id: number): Promise<{ data: ProductListItem }> {
    return API.get(`${WC_API}/products/${id}`)
  },

  addReviewToProduct(review: Record<string, unknown>): Promise<{ data: Record<string, unknown> }> {
    return API.post(`${BASE_API}/create-review`, review)
  },

  getProductReview(productID: number, userId: number, orderID: number, status: 'all' | 'approved' = 'approved'): Promise<{ data: Array<Record<string, unknown>> }> {
    return API.get(`${WC_API}/products/reviews?product=${productID}&reviewer=${userId}&status=${status}&orderID=${orderID}`)
  },

  getReviews(): Promise<{ data: Array<Record<string, unknown>> }> {
    return API.get(`${WC_API}/products/reviews?per_page=100`)
  }
}
