<template>
  <svg width="20" height="15" viewBox="0 0 20 15" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.4444 1H0.555556C0.248889 1 0 0.776 0 0.5C0 0.224 0.248889 0 0.555556 0H19.4444C19.7511 0 20 0.224 20 0.5C20 0.776 19.7511 1 19.4444 1Z" fill="black"/>
    <path d="M19.4444 8H0.555556C0.248889 8 0 7.776 0 7.5C0 7.224 0.248889 7 0.555556 7H19.4444C19.7511 7 20 7.224 20 7.5C20 7.776 19.7511 8 19.4444 8Z" fill="black"/>
    <path d="M19.4444 15H0.555556C0.248889 15 0 14.776 0 14.5C0 14.224 0.248889 14 0.555556 14H19.4444C19.7511 14 20 14.224 20 14.5C20 14.776 19.7511 15 19.4444 15Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'MenuIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
