




































import Vue from 'vue'
import Component from 'vue-class-component'
import LoginIcon from '@/components/icons/LoginIcon.vue'
import CartIcon from '@/components/icons/CartIcon.vue'
import UserIcon from '@/components/icons/UserIcon.vue'
import CatalogIcon from '@/components/icons/CatalogIcon.vue'
import FillingIcon from '@/components/icons/FillingIcon.vue'

@Component({
  components: {
    LoginIcon,
    CartIcon,
    UserIcon,
    CatalogIcon,
    FillingIcon
  }
})
export default class MobileBar extends Vue {
  get cakesInCart(): number {
    return this.$store.getters.cart.length
  }

  get isUserLogged(): boolean {
    return this.$store.getters.userToken
  }
}
