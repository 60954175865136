




















import Vue from 'vue'
import Component from 'vue-class-component'
import { REQUISITES, POLICY_LINK } from '@/shared/const'
import VisaIcon from '@/components/icons/VisaIcon.vue'
import MCIcon from '@/components/icons/MCIcon.vue'
import MirIcon from '@/components/icons/MirIcon.vue'
import ApplePayIcon from '@/components/icons/ApplePayIcon.vue'
import GooglePayIcon from '@/components/icons/GooglePayIcon.vue'

@Component({
  components: {
    VisaIcon,
    MCIcon,
    MirIcon,
    ApplePayIcon,
    GooglePayIcon
  }
})
export default class BottomPanel extends Vue {
  data(): Record<string, unknown> {
    return {
      REQUISITES,
      POLICY_LINK
    }
  }
}
