import { Commit } from 'vuex'
import API from '@/api/base'
import { Special } from '@/shared/models/specials'

interface IState {
  specials: Array<Special>
}

export default {
  state: (): IState => ({
    specials: []
  }),
  mutations: {
    SET_SPECIALS(state: IState, specials: Array<Special>): void {
      state.specials = specials
    }
  },
  actions: {
    getSpecials({ commit }: { commit: Commit }): Promise<void> {
      return API.getSpecials().then(response => {
        commit('SET_SPECIALS', response.data)
        return Promise.resolve()
      })
    }
  },
  getters: {
    specials(state: IState): Array<Special> {
      return state.specials || []
    }
  }
}
