import { Commit } from 'vuex'
import OrderAPI from '@/api/orders'
import { DeliveryType } from '@/shared/models/orders'

interface IState {
  methods: Array<DeliveryType>
}

export default {
  state: (): IState => ({
    methods: []
  }),
  mutations: {
    SET_METHODS(state: IState, methods: Array<DeliveryType>): void {
      state.methods = methods
    }
  },
  actions: {
    getDeliveryMethods({ commit }: { commit: Commit }): void {
      OrderAPI.getShippingMethods().then(response => commit('SET_METHODS', response.data))
    }
  },
  getters: {
    deliveryMethods(state: IState): Array<DeliveryType> {
      return state.methods || []
    }
  }
}
