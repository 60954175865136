import { Commit } from 'vuex'

interface IState {
  isOpen: boolean;
}

export default {
  state: (): IState => ({
    isOpen: false
  }),
  mutations: {
    CLOSE_MENU(state: IState): void {
      state.isOpen = false
    },
    OPEN_MENU(state: IState): void {
      state.isOpen = true
    }
  },
  actions: {
    toggleMobileNav({ commit }: { commit: Commit }, toShow: boolean): void {
      document.body.style.overflow = toShow ? 'hidden' : ''
      commit(toShow ? 'OPEN_MENU' : 'CLOSE_MENU')
    }
  },
  getters: {
    isMenuOpen(state: IState): boolean {
      return state.isOpen
    }
  }
}
