





















import Vue from 'vue'
import Component from 'vue-class-component'
import { CONTACTS } from '@/shared/const'
import InstaIcon from '@/components/icons/InstaIcon.vue'
import VkIcon from '@/components/icons/VkIcon.vue'

@Component({
  components: {
    InstaIcon,
    VkIcon
  }
})
export default class Socials extends Vue {
  data(): Record<string, unknown> {
    return {
      CONTACTS
    }
  }
}
