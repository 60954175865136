import { BASE_API, WC_API, API } from '@/api-config'
import { ILoginData } from '@/shared/models/auth'

export default {
  createUser(userData: ILoginData): Promise<{ data: Record<string, string> }> {
    return API.post(
      `${BASE_API}/users/add`,
      {
        first_name: userData.firstName,
        last_name: userData.lastName,
        email: userData.email,
        password: userData.password,
        phone: userData.phone,
        address: userData.address
      }
    )
  },

  updateUser(userID: string, newData: Record<string, unknown>): Promise<{ data: Record<string, unknown> }> {
    return API.put(`${BASE_API}/users/add`, newData)
  },

  updatePassword(userID: string, password: string): Promise<{ data: Record<string, unknown> }> {
    return API.post(`${WC_API}/customers/${userID}`, { password })
  },

  getUserData(userId: number): Promise<{ data: Record<string, string> }> {
    return API.get(`${WC_API}/customers/${userId}`)
  },

  getUserId(): Promise<{ data: { id: number } }> {
    return API.get<{ id: number }>(`${BASE_API}/users/me`)
  }
}
