<template>
  <svg width="75" height="75" viewBox="0 0 75 75" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M66.9977 14.3462H69.315C70.1234 14.3462 70.7789 13.6907 70.7789 12.8823C70.7789 12.074 70.1234 11.4184 69.315 11.4184H63.7523C62.9439 11.4184 62.2884 12.074 62.2884 12.8823V18.4451C62.2884 19.2534 62.9439 19.909 63.7523 19.909C64.5606 19.909 65.2161 19.2534 65.2161 18.4451V16.8344C69.6579 22.7808 72.0722 29.9958 72.0722 37.5C72.0722 56.5633 56.5633 72.0722 37.5 72.0722C18.4367 72.0722 2.92777 56.5633 2.92777 37.5C2.92777 18.4367 18.4367 2.92777 37.5 2.92777C38.9099 2.92777 40.3288 3.0134 41.7177 3.18219C42.5192 3.2791 43.2501 2.70833 43.3476 1.90568C43.4453 1.10318 42.8738 0.37329 42.0711 0.275796C40.5654 0.0928102 39.0276 0 37.5 0C27.4834 0 18.0662 3.90066 10.9835 10.9835C3.90066 18.0662 0 27.4834 0 37.5C0 47.5166 3.90066 56.9338 10.9835 64.0165C18.0662 71.0993 27.4834 75 37.5 75C47.5166 75 56.9338 71.0993 64.0165 64.0165C71.0993 56.9338 75 47.5166 75 37.5C75 29.0474 72.1762 20.9328 66.9977 14.3462Z"/>
    <path d="M15.879 22.1065C15.1958 21.6741 14.2916 21.8776 13.859 22.5603C10.5171 27.8385 9.03773 34.1862 9.6934 40.4342C10.3587 46.7748 13.2126 52.7542 17.7292 57.271C23.18 62.7218 30.34 65.4473 37.5002 65.4473C44.6603 65.4473 51.8201 62.7219 57.2711 57.271C68.1727 46.3693 68.1727 28.6309 57.2711 17.7294C52.7613 13.2197 46.7917 10.3669 40.4616 9.69644C34.2252 9.0355 27.8843 10.5033 22.6084 13.8288C21.9243 14.2599 21.7195 15.1639 22.1505 15.8478C22.5816 16.5319 23.4855 16.7367 24.1694 16.3057C34.1148 10.0374 46.875 11.4739 55.2007 19.7996C64.9608 29.5597 64.9608 45.4405 55.2007 55.2007C45.4407 64.961 29.5596 64.961 19.7996 55.2007C11.589 46.99 10.131 33.9215 16.3328 24.1267C16.7651 23.4435 16.5621 22.5391 15.879 22.1065Z"/>
    <path d="M56.5306 38.9876H58.3359C59.1442 38.9876 59.7998 38.332 59.7998 37.5237C59.7998 36.7153 59.1442 36.0598 58.3359 36.0598H56.5306C55.7223 36.0598 55.0667 36.7153 55.0667 37.5237C55.0667 38.332 55.7221 38.9876 56.5306 38.9876Z"/>
    <path d="M15.2002 37.4763C15.2002 38.2847 15.8558 38.9402 16.6641 38.9402H18.4694C19.2777 38.9402 19.9333 38.2847 19.9333 37.4763C19.9333 36.668 19.2777 36.0125 18.4694 36.0125H16.6641C15.8558 36.0125 15.2002 36.6678 15.2002 37.4763Z"/>
    <path d="M38.9876 18.4693V16.6641C38.9876 15.8557 38.3321 15.2002 37.5237 15.2002C36.7154 15.2002 36.0598 15.8557 36.0598 16.6641V18.4693C36.0598 19.2777 36.7154 19.9332 37.5237 19.9332C38.3321 19.9332 38.9876 19.2777 38.9876 18.4693Z"/>
    <path d="M36.0124 56.5305V58.3358C36.0124 59.1442 36.6679 59.7997 37.4763 59.7997C38.2846 59.7997 38.9402 59.1442 38.9402 58.3358V56.5305C38.9402 55.7222 38.2846 55.0667 37.4763 55.0667C36.6679 55.0667 36.0124 55.722 36.0124 56.5305Z"/>
    <path d="M30.1624 28.0921C29.5906 27.5206 28.664 27.5206 28.0921 28.0921C27.5204 28.6638 27.5204 29.5907 28.0921 30.1625L33.0533 35.1237C32.6731 35.8322 32.4569 36.6413 32.4569 37.5C32.4569 40.2808 34.7192 42.5431 37.5 42.5431C40.2808 42.5431 42.5431 40.2808 42.5431 37.5C42.5431 36.6413 42.3267 35.8322 41.9467 35.1237L51.9572 25.1133C52.5288 24.5416 52.5288 23.6147 51.9572 23.0429C51.3854 22.4714 50.4587 22.4714 49.8868 23.0429L39.8763 33.0534C39.1678 32.6732 38.3587 32.457 37.5 32.457C36.6413 32.457 35.8322 32.6733 35.1237 33.0534L30.1624 28.0921ZM39.6153 37.5C39.6153 38.6665 38.6664 39.6154 37.5 39.6154C36.3336 39.6154 35.3847 38.6665 35.3847 37.5C35.3847 36.3336 36.3336 35.3847 37.5 35.3847C38.6664 35.3847 39.6153 36.3336 39.6153 37.5Z"/>
    <path d="M19.7987 19.7987C20.0725 19.5264 20.229 19.1487 20.229 18.7637C20.229 18.3787 20.0725 18.0025 19.7987 17.7287C19.5264 17.4566 19.1501 17.2998 18.7637 17.2998C18.3787 17.2998 18.0011 17.4564 17.7288 17.7287C17.4566 18.001 17.2999 18.3787 17.2999 18.7637C17.2999 19.1502 17.4565 19.5264 17.7288 19.7987C18.0011 20.0708 18.3787 20.2276 18.7637 20.2276C19.1501 20.2276 19.5264 20.0708 19.7987 19.7987Z"/>
    <path d="M47.6403 4.37381C48.0269 4.37381 48.4046 4.21849 48.6767 3.94489C48.949 3.67261 49.1058 3.29625 49.1058 2.90993C49.1058 2.52493 48.9492 2.14724 48.6767 1.87496C48.4046 1.60283 48.0269 1.44604 47.6403 1.44604C47.2553 1.44604 46.8776 1.60268 46.6055 1.87496C46.3332 2.14724 46.1764 2.52493 46.1764 2.90993C46.1764 3.29639 46.3331 3.67261 46.6055 3.94489C46.8776 4.21849 47.2553 4.37381 47.6403 4.37381Z"/>
  </svg>
</template>

<script>
export default {
  name: 'TimerIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
