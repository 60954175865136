















































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Component
export default class BezeInput extends Vue {
  @Prop({ default: 'text' }) type!: 'text' | 'tel' | 'email' | 'password'
  @Prop({ default: '' }) placeholder!: string
  @Prop({ default: 'input' }) tag!: 'input' | 'textarea'
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: false }) focused!: boolean
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) label!: string
  @Prop({ default: 'sm' }) size!: 'xs' | 'sm' | 'md'
  @Prop({ default: 'white' }) theme!: 'primary' | 'muted' | 'ternary' | 'white'

  inputId = 0

  created(): void {
    this.inputId = Date.now() * (Math.floor(Math.random() * 100))
  }

  @Watch('focused')
  focusInput(toFocus: boolean): void {
    if (toFocus) {
      const input: HTMLInputElement = this.$refs.input as HTMLInputElement
      input.focus()
    }
  }
}
