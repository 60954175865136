<template>
  <svg width="21" height="22" viewBox="0 0 21 22" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.9954 13.1931C20.9967 13.1732 20.9967 13.1531 20.9954 13.1332C20.9838 13.1075 20.9698 13.0829 20.9537 13.0596L20.912 13.0044L12.6778 5.18215C12.6106 5.11698 12.525 5.07362 12.4325 5.05791C11.7269 4.96002 11.0157 4.90774 10.3033 4.90147C10.0316 4.69142 9.71484 4.54653 9.37759 4.47815C9.2989 3.6315 9.39148 1.71735 10.9791 0.870705C11.204 0.743651 11.2828 0.459347 11.155 0.235722C11.0272 0.0120978 10.7412 -0.0661823 10.5162 0.0608719C8.4565 1.16979 8.32227 3.47966 8.36856 4.47354C7.95563 4.56551 7.57707 4.77125 7.27621 5.06712C3.89271 5.57326 1.39327 7.07329 0.0370915 9.41997V9.44298V9.47059C0.0139485 9.52448 0.00139347 9.58228 6.27529e-05 9.64084V17.8588C-0.00364012 18.0838 0.156914 18.2784 0.379607 18.3189L20.4538 22H20.5371C20.7927 22 21 21.794 21 21.5399V13.2943C21 13.2943 21 13.2943 21 13.2621C21.0002 13.239 20.9986 13.216 20.9954 13.1931ZM8.90547 5.32019C9.64171 5.31766 10.2406 5.90887 10.2431 6.64077C10.2457 7.37267 9.65097 7.96802 8.91473 7.97055C8.1785 7.97308 7.57961 7.38187 7.57707 6.64997C7.57707 6.64842 7.57707 6.64692 7.57707 6.64537C7.57707 5.91531 8.17103 5.32272 8.90547 5.32019ZM6.72541 6.09321C6.67779 6.27192 6.65291 6.45591 6.65135 6.64077C6.61478 7.88037 7.59593 8.91475 8.84287 8.9511C10.0898 8.98745 11.1303 8.01208 11.1669 6.77248C11.1767 6.44113 11.1125 6.11173 10.9791 5.80793C11.5021 5.83554 11.9048 5.88155 12.0899 5.90456L19.1161 12.5811L1.25904 9.30494C2.4023 7.66687 4.24448 6.59476 6.72541 6.09321ZM0.939667 17.4723V15.2637L20.0882 18.6916L20.0928 20.9877L0.939667 17.4723ZM0.939667 14.3296V12.9492L20.0882 16.3772L20.0928 17.7668L0.939667 14.3296ZM0.939667 12.0243V10.1838L15.1448 12.7927L20.0835 13.713L20.0928 15.4661L0.939667 12.0243Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'FillingIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
