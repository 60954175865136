<template>
  <svg width="21" height="19" viewBox="0 0 21 19" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3038 2.53584C18.2041 1.48508 16.7483 0.910773 15.1946 0.910773C13.6409 0.910773 12.1806 1.48933 11.081 2.54009L10.5067 3.08887L9.92347 2.53159C8.82383 1.48082 7.35913 0.89801 5.8054 0.89801C4.25611 0.89801 2.79586 1.47657 1.70068 2.52308C0.60104 3.57384 -0.00442759 4.96919 2.43777e-05 6.45387C2.43777e-05 7.93856 0.609944 9.32965 1.70958 10.3804L10.0704 18.3696C10.1861 18.4802 10.342 18.5398 10.4933 18.5398C10.6447 18.5398 10.8005 18.4845 10.9163 18.3739L19.2949 10.3974C20.3945 9.34666 21 7.95132 21 6.46663C21.0044 4.98195 20.4034 3.58661 19.3038 2.53584ZM18.449 9.58489L10.4933 17.1572L2.55546 9.57213C1.68287 8.73833 1.20206 7.63226 1.20206 6.45387C1.20206 5.27549 1.67842 4.16942 2.551 3.33987C3.41914 2.51032 4.57665 2.05087 5.8054 2.05087C7.03859 2.05087 8.20056 2.51032 9.07314 3.34412L10.0793 4.30555C10.3152 4.53102 10.6937 4.53102 10.9296 4.30555L11.9269 3.35263C12.7994 2.51882 13.9614 2.05938 15.1902 2.05938C16.4189 2.05938 17.5764 2.51882 18.449 3.34838C19.3216 4.18218 19.7979 5.28825 19.7979 6.46663C19.8024 7.64502 19.3216 8.75109 18.449 9.58489Z"/>
  </svg>
</template>

<script>
export default {
  name: 'FavoriteIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
