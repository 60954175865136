




import Vue from 'vue'
import Component from 'vue-class-component'
import { CONTACTS } from '@/shared/const'

@Component
export default class Phone extends Vue {
  data() {
    return {
      CONTACTS
    }
  }
}
