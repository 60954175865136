<template>
  <svg width="46" height="19" viewBox="0 0 46 19" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.2836 8.87131V14.1941H19.6218V1.04922H24.0293C24.5554 1.03806 25.0784 1.13287 25.5683 1.32818C26.0581 1.5235 26.5051 1.81545 26.8833 2.18719C27.2653 2.53636 27.5694 2.96459 27.775 3.44308C27.9806 3.92157 28.0831 4.43925 28.0755 4.96132C28.0864 5.48617 27.9855 6.00723 27.7798 6.48877C27.574 6.97031 27.2682 7.40095 26.8833 7.75117C26.1126 8.49793 25.1612 8.87096 24.0293 8.87026H21.2836V8.87131ZM21.2836 2.66755V7.25613H24.0705C24.3761 7.26533 24.6801 7.20964 24.9633 7.09261C25.2464 6.97558 25.5024 6.7998 25.7148 6.5765C25.9262 6.36772 26.0942 6.11799 26.2089 5.8421C26.3236 5.5662 26.3827 5.26974 26.3827 4.97023C26.3827 4.67072 26.3236 4.37426 26.2089 4.09836C26.0942 3.82247 25.9262 3.57275 25.7148 3.36396C25.505 3.13596 25.25 2.95587 24.9665 2.83562C24.683 2.71538 24.3776 2.65773 24.0705 2.6665H21.2836V2.66755Z" fill="#5F6368"/>
    <path d="M31.9049 4.90698C33.1332 4.90698 34.1028 5.24051 34.8136 5.90756C35.5245 6.5746 35.8796 7.48917 35.8789 8.65126V14.1943H34.2893V12.9462H34.217C33.5289 13.974 32.6137 14.4879 31.4714 14.4879C30.4963 14.4879 29.6805 14.1943 29.0241 13.6069C28.709 13.3374 28.4571 12.9998 28.2868 12.6187C28.1164 12.2377 28.0319 11.8228 28.0393 11.4044C28.0393 10.4738 28.3855 9.73364 29.0777 9.18406C29.77 8.63448 30.6941 8.35899 31.8502 8.3576C32.837 8.3576 33.6497 8.54114 34.2883 8.90822V8.52226C34.2901 8.237 34.2295 7.95489 34.1108 7.69638C33.9921 7.43788 33.8182 7.20946 33.6018 7.02769C33.1624 6.62482 32.5897 6.405 31.9978 6.41204C31.0695 6.41204 30.3349 6.80989 29.7941 7.60559L28.3304 6.669C29.1355 5.49432 30.327 4.90698 31.9049 4.90698ZM29.7548 11.4411C29.7537 11.656 29.8032 11.8681 29.899 12.0596C29.9949 12.2512 30.1345 12.4167 30.306 12.5424C30.6736 12.8361 31.1298 12.9918 31.5973 12.9829C32.2986 12.9817 32.9708 12.6981 33.4666 12.1942C34.0171 11.6677 34.2924 11.0499 34.2924 10.3409C33.7742 9.92138 33.0517 9.71162 32.1248 9.71162C31.4497 9.71162 30.8868 9.87698 30.4361 10.2077C29.9809 10.5433 29.7548 10.9513 29.7548 11.4411V11.4411Z" fill="#5F6368"/>
    <path d="M45.0035 5.20044L39.4544 18.1586H37.7389L39.7981 13.6245L36.1493 5.20044H37.9556L40.5929 11.6612H40.629L43.194 5.20044H45.0035Z" fill="#5F6368"/>
    <path d="M14.5681 7.72419C14.5688 7.20959 14.5259 6.69588 14.4401 6.18872H7.43152V9.09709H11.4457C11.3636 9.5616 11.1897 10.0044 10.9346 10.3988C10.6795 10.7932 10.3485 11.131 9.96143 11.3919V13.2798H12.3572C13.7599 11.9656 14.5681 10.0222 14.5681 7.72419Z" fill="#4285F4"/>
    <path d="M7.43145 15.1035C9.43702 15.1035 11.1257 14.4344 12.3571 13.2807L9.96137 11.3928C9.29457 11.8522 8.43578 12.1144 7.43145 12.1144C5.49299 12.1144 3.84766 10.7866 3.25931 8.99731H0.791321V10.9429C1.40988 12.1936 2.35838 13.245 3.53092 13.9797C4.70346 14.7144 6.05389 15.1035 7.43145 15.1035V15.1035Z" fill="#34A853"/>
    <path d="M3.25934 8.99748C2.9483 8.05988 2.9483 7.04453 3.25934 6.10694V4.16138H0.791357C0.271001 5.2135 0 6.3747 0 7.55221C0 8.72972 0.271001 9.89091 0.791357 10.943L3.25934 8.99748Z" fill="#FBBC04"/>
    <path d="M7.43145 2.98963C8.4913 2.97203 9.5154 3.37892 10.2824 4.12235L12.4036 1.96703C11.0585 0.683356 9.27663 -0.0214029 7.43145 0.000495448C6.05389 0.000559243 4.70346 0.389664 3.53092 1.12437C2.35838 1.85908 1.40988 2.91046 0.791321 4.16116L3.25931 6.10672C3.84766 4.31743 5.49299 2.98963 7.43145 2.98963Z" fill="#EA4335"/>
  </svg>
</template>

<script>
export default {
  name: 'GooglePayIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
