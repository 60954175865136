import API from '@/api/products'
import { Commit } from 'vuex'
import { CakeCategoryItem } from '@/shared/models/cakes'
import Vue from 'vue'

interface IState {
  cakeCategories: Array<CakeCategoryItem>,
  cakeCategoriesMap: Record<string, CakeCategoryItem>,
}

export default {
  state: (): IState => ({
    cakeCategories: [],
    cakeCategoriesMap: {}
  }),
  mutations: {
    SET_CATEGORIES(state: IState, categories: Array<CakeCategoryItem>): void {
      state.cakeCategories = [...categories]
      // eslint-disable-next-line no-unused-expressions
      categories?.forEach(cat => {
        Vue.set(
          state.cakeCategoriesMap,
          cat.slug,
          {
            id: cat.id,
            name: cat.name,
            description: cat.description,
            count: cat.count
          }
        )
      })
    }
  },
  actions: {
    getCategories({ commit }: { commit: Commit }): void {
      API.getCakeCategories().then(response => {
        commit(
          'SET_CATEGORIES',
          response?.data
            .filter(cat => cat.slug !== 'misc')
            .sort((a, b) => Number(a.menu_order) - Number(b.menu_order)) || [])
      })
    }
  },
  getters: {
    categories(state: IState): Array<CakeCategoryItem> {
      return state.cakeCategories || []
    },
    categoriesMap(state: IState): Record<string, CakeCategoryItem> {
      return state.cakeCategoriesMap
    }
  }
}
