<template>
  <svg width="38" height="12" viewBox="0 0 38 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.9333 11.4162L15.8635 0.559082H18.8794L17.0095 11.4162H13.9333Z" fill="#3C58BF"/>
    <path d="M13.9333 11.4162L16.4064 0.559082H18.8794L17.0095 11.4162H13.9333Z" fill="#293688"/>
    <path d="M27.927 0.679561C27.3238 0.438291 26.3587 0.197021 25.1524 0.197021C22.1365 0.197021 19.9651 1.70496 19.9651 3.87639C19.9651 5.50496 21.473 6.3494 22.6794 6.89226C23.8857 7.43512 24.2476 7.79702 24.2476 8.27956C24.2476 9.00337 23.2826 9.36528 22.4381 9.36528C21.2318 9.36528 20.5683 9.18432 19.5429 8.7621L19.1206 8.58115L18.6984 11.0542C19.4222 11.3558 20.7492 11.6573 22.1365 11.6573C25.3333 11.6573 27.4445 10.1494 27.4445 7.85734C27.4445 6.59067 26.6603 5.62559 24.8508 4.84147C23.7651 4.29861 23.1016 3.99702 23.1016 3.45416C23.1016 2.97162 23.6445 2.48908 24.8508 2.48908C25.8762 2.48908 26.6 2.67004 27.1429 2.91131L27.4445 3.03194L27.927 0.679561Z" fill="#3C58BF"/>
    <path d="M27.927 0.679561C27.3238 0.438291 26.3587 0.197021 25.1524 0.197021C22.1365 0.197021 20.5079 1.70496 20.5079 3.87639C20.5079 5.50496 21.473 6.3494 22.6794 6.89226C23.8857 7.43512 24.2476 7.79702 24.2476 8.27956C24.2476 9.00337 23.2826 9.36528 22.4381 9.36528C21.2318 9.36528 20.5683 9.18432 19.5429 8.7621L19.1206 8.58115L18.6984 11.0542C19.4222 11.3558 20.7492 11.6573 22.1365 11.6573C25.3333 11.6573 27.4445 10.1494 27.4445 7.85734C27.4445 6.59067 26.6603 5.62559 24.8508 4.84147C23.7651 4.29861 23.1016 3.99702 23.1016 3.45416C23.1016 2.97162 23.6445 2.48908 24.8508 2.48908C25.8762 2.48908 26.6 2.67004 27.1429 2.91131L27.4445 3.03194L27.927 0.679561Z" fill="#293688"/>
    <path d="M33.1143 0.559082C32.3905 0.559082 31.8476 0.6194 31.546 1.34321L27.0222 11.4162H30.2794L30.8825 9.6067H34.7428L35.1047 11.4162H38L35.4667 0.559082H33.1143ZM31.727 7.79718C31.9079 7.25432 32.9333 4.60035 32.9333 4.60035C32.9333 4.60035 33.1746 3.93686 33.3555 3.51464L33.5365 4.54003C33.5365 4.54003 34.1397 7.25432 34.2603 7.8575H31.727V7.79718Z" fill="#3C58BF"/>
    <path d="M33.8381 0.559082C33.1143 0.559082 32.5714 0.6194 32.2698 1.34321L27.0222 11.4162H30.2794L30.8825 9.6067H34.7428L35.1047 11.4162H38L35.4667 0.559082H33.8381ZM31.727 7.79718C31.9682 7.194 32.9333 4.60035 32.9333 4.60035C32.9333 4.60035 33.1746 3.93686 33.3555 3.51464L33.5365 4.54003C33.5365 4.54003 34.1397 7.25432 34.2603 7.8575H31.727V7.79718Z" fill="#293688"/>
    <path d="M8.3841 8.15906L8.08251 6.59081C7.53966 4.78129 5.79045 2.79081 3.86029 1.82573L6.57458 11.4765H9.83172L14.7174 0.619385H11.4603L8.3841 8.15906Z" fill="#3C58BF"/>
    <path d="M8.3841 8.15906L8.08251 6.59081C7.53966 4.78129 5.79045 2.79081 3.86029 1.82573L6.57458 11.4765H9.83172L14.7174 0.619385H12.0635L8.3841 8.15906Z" fill="#293688"/>
    <path d="M0 0.559082L0.542857 0.679717C4.40317 1.58448 7.05714 3.87654 8.08254 6.59083L6.99683 1.46384C6.81587 0.740034 6.27302 0.559082 5.60952 0.559082H0Z" fill="#FFBC00"/>
    <path d="M0 0.559082C3.86032 1.46384 7.05714 3.81623 8.08254 6.53051L7.05714 2.24797C6.87619 1.52416 6.27302 1.10194 5.60952 1.10194L0 0.559082Z" fill="#F7981D"/>
    <path d="M0 0.559082C3.86032 1.46384 7.05714 3.81623 8.08254 6.53051L7.35873 4.17813C7.17778 3.45432 6.93651 2.73051 6.09206 2.42892L0 0.559082Z" fill="#ED7C00"/>
    <path d="M11.4 7.79713L9.34923 5.74633L8.38415 8.0384L8.14288 6.53046C7.60002 4.72094 5.85081 2.73046 3.92065 1.76538L6.63494 11.4162H9.89208L11.4 7.79713Z" fill="#051244"/>
    <path d="M17.0095 11.4162L14.4159 8.76221L13.9333 11.4162H17.0095Z" fill="#051244"/>
    <path d="M23.946 7.67651C24.1873 7.91778 24.308 8.09874 24.2476 8.34001C24.2476 9.06382 23.2826 9.42572 22.4381 9.42572C21.2318 9.42572 20.5683 9.24477 19.5429 8.82255L19.1206 8.64159L18.6984 11.1146C19.4222 11.4162 20.7492 11.7178 22.1365 11.7178C24.0667 11.7178 25.6349 11.1749 26.5397 10.2098L23.946 7.67651Z" fill="#051244"/>
    <path d="M27.4445 11.4162H30.2794L30.8826 9.60671H34.7429L35.1048 11.4162H38L36.9746 7.01306L33.3556 3.51465L33.5365 4.47973C33.5365 4.47973 34.1397 7.19401 34.2603 7.79719H31.727C31.9683 7.19401 32.9333 4.60036 32.9333 4.60036C32.9333 4.60036 33.1746 3.93687 33.3556 3.51465" fill="#051244"/>
  </svg>
</template>

<script>
export default {
  name: 'VisaIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
