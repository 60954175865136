
















import { Component, Vue } from 'vue-property-decorator'
import Header from '@/components/header/Header.vue'
import About from '@/components/widgets/About'
import Footer from '@/components/footer/Footer.vue'
import { CakeCategoryItem } from '@/shared/models/cakes'
import TransitionPage from '@/pages/TransitionPage.vue'
import LoginForm from '@/components/common/LoginForm.vue'
import MobileBar from '@/components/mobile-bar/MobileBar.vue'
import OrderRequestModal from '@/components/common/RequestOrderModal.vue'

@Component({
  components: {
    Header,
    Footer,
    About,
    TransitionPage,
    LoginForm,
    MobileBar,
    OrderRequestModal
  }
})
export default class DefaultView extends Vue {
  get cakeCategories(): Array<CakeCategoryItem> {
    return this.$store.getters.categories
  }

  mounted(): void {
    !this.cakeCategories.length && this.$store.dispatch('getCategories')
  }
}
