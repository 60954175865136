









































import Vue from 'vue'
import Component from 'vue-class-component'
import BezeButton from '@/components/common/BezeButton.vue'
import BezeInput from '@/components/common/BezeInput.vue'
import { mask } from 'vue-the-mask'
import OrderAPI from '@/api/orders'
import { ModalData } from '@/shared/models/modals'
import CloseIcon from '@/components/icons/CloseIcon'

@Component({
  components: {
    BezeButton,
    BezeInput,
    CloseIcon
  },
  directives: {
    mask
  }
})
export default class OrderRequestModal extends Vue {
  name = ''
  phone = ''
  isBtnLoading = false
  successMessage = ''

  get isFormCorrect(): boolean {
    return this.phone.length > 7 && this.name.length > 1
  }

  get isRequestModalVisible(): boolean {
    return this.$store.getters.isRequestModalVisible
  }

  get requestModalData(): ModalData {
    return this.$store.getters.modalData
  }

  closeModal(): void {
    this.$store.dispatch('toggleModal', { open: false, modalName: 'requestModal', data: null })
  }

  makeOrderRequest(): void {
    const data = {
      ...this.requestModalData,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      roistat_visit: window?.roistat?.getVisit()?.toString() || '',
      name: this.name,
      phone: this.phone
    }
    this.isBtnLoading = true
    OrderAPI.createRequestOrder(data).then(response => {
      this.name = ''
      this.phone = ''
      this.successMessage = response?.data?.response?.message || 'Заявка успешно отправлена. Мы скоро с вами свяжемся'
    }).catch(error => {
      this.$toasted.error(error.response?.textContent || 'Возникла ошибка при отправке заявки. Попробуйте еще раз')
    }).finally(() => {
      this.isBtnLoading = false
    })
  }
}
