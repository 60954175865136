<template>
  <svg width="21" height="22" viewBox="0 0 21 22" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5783 13.0636C11.7775 13.0829 11.1627 15.4563 10.9578 17.463L10.8647 18.3506H10.1007L10.0076 17.463C9.80269 15.4563 9.16924 13.0829 5.49886 13.0636C4.88404 11.7514 4.6232 10.6901 4.6232 9.49377C4.6232 6.50294 6.98938 3.70495 10.492 3.70495C13.9946 3.70495 16.3235 6.48354 16.3235 9.51315C16.3236 10.6901 16.2118 11.7514 15.5783 13.0636ZM10.4548 0.077301C4.67915 0.077301 -0.0532227 4.97852 -0.0532227 10.9795C-0.0532227 16.9999 4.67915 21.901 10.4548 21.901C16.2863 21.901 21 16.9999 21 10.9795C21 4.97854 16.2863 0.077301 10.4548 0.077301Z" />
  </svg>
</template>

<script>
export default {
  name: 'GisIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
