






























import Vue from 'vue'
import Component from 'vue-class-component'
import LoginIcon from '@/components/icons/LoginIcon.vue'
import FavoriteIcon from '@/components/icons/FavoriteIcon.vue'
import CartIcon from '@/components/icons/CartIcon.vue'
import UserIcon from '@/components/icons/UserIcon.vue'
import MenuIcon from '@/components/icons/MenuIcon.vue'
import GisIcon from '@/components/icons/GisIcon.vue'

@Component({
  components: {
    LoginIcon,
    FavoriteIcon,
    CartIcon,
    UserIcon,
    MenuIcon,
    GisIcon
  }
})
export default class UserBar extends Vue {
  get cakesInCart(): number {
    return this.$store.getters.cart.length
  }

  get isUserLogged(): boolean {
    return this.$store.getters.userToken
  }
}
