<template>
  <svg width="37" height="24" viewBox="0 0 37 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M37 11.9574C37 18.2304 32.0667 23.3574 25.9 23.3574C19.7921 23.3574 14.8 18.2304 14.8 11.9574C14.8 5.68436 19.7334 0.557373 25.8413 0.557373C32.0667 0.557373 37 5.68436 37 11.9574Z" fill="#FFB600"/>
    <path d="M25.9 0.557373C32.008 0.557373 37 5.68436 37 11.9574C37 18.2304 32.0667 23.3574 25.9 23.3574C19.7921 23.3574 14.8 18.2304 14.8 11.9574" fill="#F7981D"/>
    <path d="M25.9 0.557373C32.0079 0.557373 37 5.68436 37 11.9574C37 18.2304 32.0666 23.3574 25.9 23.3574" fill="#FF8500"/>
    <path d="M10.9825 0.557373C4.93333 0.617691 0 5.68436 0 11.9574C0 18.2304 4.93333 23.3574 11.1 23.3574C13.9778 23.3574 16.5619 22.2113 18.5587 20.4018C18.9698 20.0399 19.3222 19.6177 19.6746 19.1955H17.3841C17.0905 18.8336 16.7968 18.4113 16.5619 18.0494H20.4968C20.7317 17.6875 20.9667 17.2653 21.1429 16.8431H15.9159C15.7397 16.4812 15.5635 16.059 15.446 15.6367H21.554C21.9063 14.4907 22.1413 13.2844 22.1413 12.0177C22.1413 11.1732 22.0238 10.3891 21.9063 9.60499H15.0937C15.1524 9.18277 15.2698 8.82086 15.3873 8.39864H21.4952C21.3778 7.97642 21.2016 7.5542 21.0254 7.19229H15.8571C16.0333 6.77007 16.2683 6.40817 16.5032 5.98594H20.4381C20.2032 5.56372 19.9095 5.1415 19.5571 4.77959H17.3841C17.7365 4.35737 18.0889 3.99547 18.5 3.63356C16.5619 1.76372 13.919 0.678008 11.0413 0.678008C11.0413 0.557373 11.0413 0.557373 10.9825 0.557373Z" fill="#FF5050"/>
    <path d="M0 11.9576C0 18.2306 4.93333 23.3576 11.1 23.3576C13.9778 23.3576 16.5619 22.2116 18.5587 20.402C18.9698 20.0401 19.3222 19.6179 19.6746 19.1957H17.3841C17.0905 18.8338 16.7968 18.4116 16.5619 18.0497H20.4968C20.7317 17.6877 20.9667 17.2655 21.1429 16.8433H15.9159C15.7397 16.4814 15.5635 16.0592 15.446 15.637H21.554C21.9063 14.4909 22.1413 13.2846 22.1413 12.0179C22.1413 11.1735 22.0238 10.3893 21.9063 9.60521H15.0937C15.1524 9.18298 15.2698 8.82108 15.3873 8.39886H21.4952C21.3778 7.97663 21.2016 7.55441 21.0254 7.19251H15.8571C16.0333 6.77029 16.2683 6.40838 16.5032 5.98616H20.4381C20.2032 5.56394 19.9095 5.14171 19.5571 4.77981H17.3841C17.7365 4.35759 18.0889 3.99568 18.5 3.63378C16.5619 1.76394 13.919 0.678223 11.0413 0.678223H10.9825" fill="#E52836"/>
    <path d="M11.1 23.3576C13.9778 23.3576 16.5619 22.2116 18.5587 20.402C18.9698 20.0401 19.3222 19.6179 19.6746 19.1957H17.3841C17.0905 18.8338 16.7968 18.4116 16.5619 18.0497H20.4968C20.7318 17.6877 20.9667 17.2655 21.1429 16.8433H15.9159C15.7397 16.4814 15.5635 16.0592 15.446 15.637H21.554C21.9064 14.4909 22.1413 13.2846 22.1413 12.0179C22.1413 11.1735 22.0238 10.3893 21.9064 9.60521H15.0937C15.1524 9.18298 15.2698 8.82108 15.3873 8.39886H21.4952C21.3778 7.97663 21.2016 7.55441 21.0254 7.19251H15.8571C16.0333 6.77029 16.2683 6.40838 16.5032 5.98616H20.4381C20.2032 5.56394 19.9095 5.14171 19.5571 4.77981H17.3841C17.7365 4.35759 18.0889 3.99568 18.5 3.63378C16.5619 1.76394 13.9191 0.678223 11.0413 0.678223H10.9825" fill="#CB2026"/>
    <path d="M15.0349 14.8529L15.2111 13.8275C15.1524 13.8275 15.0349 13.8878 14.9174 13.8878C14.5063 13.8878 14.4476 13.6465 14.5063 13.5259L14.8587 11.4148H15.5047L15.6809 10.2687H15.0936L15.2111 9.54492H14.0365C14.0365 9.54492 13.3317 13.5259 13.3317 14.0084C13.3317 14.7322 13.7428 15.0338 14.2714 15.0338C14.6238 15.0338 14.9174 14.9132 15.0349 14.8529Z" fill="white"/>
    <path d="M15.446 12.9226C15.446 14.6115 16.5619 15.0337 17.5016 15.0337C18.3826 15.0337 18.7349 14.8528 18.7349 14.8528L18.9699 13.7067C18.9699 13.7067 18.3238 14.0083 17.7365 14.0083C16.4445 14.0083 16.6794 13.0432 16.6794 13.0432H19.0873C19.0873 13.0432 19.2635 12.2591 19.2635 11.9575C19.2635 11.1734 18.8524 10.2083 17.5603 10.2083C16.327 10.0877 15.446 11.4147 15.446 12.9226ZM17.5016 11.1734C18.1476 11.1734 18.0302 11.9575 18.0302 12.0178H16.7381C16.7381 11.9575 16.8556 11.1734 17.5016 11.1734Z" fill="white"/>
    <path d="M24.9603 14.8527L25.1952 13.5258C25.1952 13.5258 24.6079 13.8273 24.1968 13.8273C23.3746 13.8273 23.0222 13.1639 23.0222 12.44C23.0222 10.9924 23.727 10.2083 24.5492 10.2083C25.1365 10.2083 25.6064 10.5702 25.6064 10.5702L25.7825 9.30354C25.7825 9.30354 25.0778 9.00195 24.4317 9.00195C23.081 9.00195 21.7302 10.2083 21.7302 12.5004C21.7302 14.0083 22.4349 15.0337 23.8444 15.0337C24.3143 15.0337 24.9603 14.8527 24.9603 14.8527Z" fill="white"/>
    <path d="M8.57458 10.0876C7.75236 10.0876 7.16506 10.3289 7.16506 10.3289L6.98887 11.3543C6.98887 11.3543 7.51744 11.113 8.28093 11.113C8.69204 11.113 9.04442 11.1734 9.04442 11.5353C9.04442 11.7765 8.98569 11.8368 8.98569 11.8368C8.98569 11.8368 8.63331 11.8368 8.45712 11.8368C7.45871 11.8368 6.34283 12.2591 6.34283 13.6464C6.34283 14.7321 7.0476 14.9734 7.45871 14.9734C8.28093 14.9734 8.63331 14.4305 8.69204 14.4305L8.63331 14.913H9.69045L10.1603 11.5956C10.1603 10.148 8.98569 10.0876 8.57458 10.0876ZM8.8095 12.8019C8.8095 12.9829 8.69204 13.948 7.98728 13.948C7.6349 13.948 7.51744 13.6464 7.51744 13.4654C7.51744 13.1638 7.69363 12.7416 8.57458 12.7416C8.75077 12.8019 8.8095 12.8019 8.8095 12.8019Z" fill="white"/>
    <path d="M11.2761 14.9734C11.5698 14.9734 13.0381 15.0337 13.0381 13.4051C13.0381 11.8972 11.6285 12.1988 11.6285 11.5956C11.6285 11.294 11.8634 11.1734 12.2746 11.1734C12.4508 11.1734 13.0968 11.2337 13.0968 11.2337L13.273 10.148C13.273 10.148 12.8619 10.0273 12.1571 10.0273C11.2761 10.0273 10.3952 10.3892 10.3952 11.5956C10.3952 12.9829 11.8635 12.8623 11.8635 13.4051C11.8635 13.767 11.4523 13.8273 11.1587 13.8273C10.6301 13.8273 10.1015 13.6464 10.1015 13.6464L9.92535 14.7321C9.98408 14.8527 10.2777 14.9734 11.2761 14.9734Z" fill="white"/>
    <path d="M34.7095 9.12256L34.4746 10.7511C34.4746 10.7511 34.0047 10.148 33.3587 10.148C32.3016 10.148 31.3619 11.4749 31.3619 13.0432C31.3619 14.0083 31.8317 15.0337 32.8301 15.0337C33.5349 15.0337 33.946 14.5511 33.946 14.5511L33.8873 14.9734H35.0619L35.9428 9.18288L34.7095 9.12256ZM34.1809 12.3194C34.1809 12.9829 33.8873 13.8273 33.2412 13.8273C32.8301 13.8273 32.5952 13.4654 32.5952 12.8622C32.5952 11.8972 33.0063 11.294 33.5349 11.294C33.946 11.294 34.1809 11.5956 34.1809 12.3194Z" fill="white"/>
    <path d="M2.173 14.913L2.87776 10.5702L2.99522 14.913H3.81745L5.34443 10.5702L4.6984 14.913H5.93173L6.87141 9.12256H4.93332L3.75872 12.6813L3.69999 9.12256H1.99681L1.05713 14.913H2.173Z" fill="white"/>
    <path d="M20.3793 14.913C20.7317 12.9225 20.7905 11.294 21.6127 11.5956C21.7301 10.8114 21.9063 10.5098 22.0238 10.2083C22.0238 10.2083 21.9651 10.2083 21.7889 10.2083C21.2603 10.2083 20.8492 10.9321 20.8492 10.9321L20.9666 10.2686H19.8508L19.0873 14.9733H20.3793V14.913Z" fill="white"/>
    <path d="M27.6619 10.0876C26.8397 10.0876 26.2524 10.3289 26.2524 10.3289L26.0762 11.3543C26.0762 11.3543 26.6048 11.113 27.3683 11.113C27.7794 11.113 28.1318 11.1734 28.1318 11.5353C28.1318 11.7765 28.073 11.8368 28.073 11.8368C28.073 11.8368 27.7207 11.8368 27.5445 11.8368C26.5461 11.8368 25.4302 12.2591 25.4302 13.6464C25.4302 14.7321 26.1349 14.9734 26.5461 14.9734C27.3683 14.9734 27.7207 14.4305 27.7794 14.4305L27.7207 14.913H28.7778L29.2476 11.5956C29.3064 10.148 28.073 10.0876 27.6619 10.0876ZM27.9556 12.8019C27.9556 12.9829 27.8381 13.948 27.1334 13.948C26.781 13.948 26.6635 13.6464 26.6635 13.4654C26.6635 13.1638 26.8397 12.7416 27.7207 12.7416C27.8968 12.8019 27.8968 12.8019 27.9556 12.8019Z" fill="white"/>
    <path d="M30.246 14.913C30.5984 12.9225 30.6572 11.294 31.4794 11.5956C31.5968 10.8114 31.773 10.5098 31.8905 10.2083C31.8905 10.2083 31.8318 10.2083 31.6556 10.2083C31.127 10.2083 30.7159 10.9321 30.7159 10.9321L30.8333 10.2686H29.7175L28.954 14.9733H30.246V14.913Z" fill="white"/>
    <path d="M13.2143 14.0084C13.2143 14.7322 13.6254 15.0338 14.154 15.0338C14.5651 15.0338 14.9175 14.9132 15.0349 14.8529L15.2111 13.8275C15.1524 13.8275 15.0349 13.8878 14.9175 13.8878C14.5064 13.8878 14.4476 13.6465 14.5064 13.5259L14.8587 11.4148H15.5048L15.681 10.2687H15.0937L15.2111 9.54492" fill="#DCE5E5"/>
    <path d="M16.0333 12.9226C16.0333 14.6115 16.5619 15.0337 17.5016 15.0337C18.3825 15.0337 18.7349 14.8528 18.7349 14.8528L18.9698 13.7067C18.9698 13.7067 18.3238 14.0083 17.7365 14.0083C16.4444 14.0083 16.6794 13.0432 16.6794 13.0432H19.0873C19.0873 13.0432 19.2635 12.2591 19.2635 11.9575C19.2635 11.1734 18.8524 10.2083 17.5603 10.2083C16.327 10.0877 16.0333 11.4147 16.0333 12.9226ZM17.5016 11.1734C18.1476 11.1734 18.2651 11.9575 18.2651 12.0178H16.7381C16.7381 11.9575 16.8555 11.1734 17.5016 11.1734Z" fill="#DCE5E5"/>
    <path d="M24.9603 14.8527L25.1952 13.5258C25.1952 13.5258 24.6079 13.8273 24.1968 13.8273C23.3746 13.8273 23.0222 13.1639 23.0222 12.44C23.0222 10.9924 23.727 10.2083 24.5492 10.2083C25.1365 10.2083 25.6063 10.5702 25.6063 10.5702L25.7825 9.30354C25.7825 9.30354 25.0778 9.00195 24.4317 9.00195C23.0809 9.00195 22.3174 10.2083 22.3174 12.5004C22.3174 14.0083 22.4349 15.0337 23.8444 15.0337C24.3143 15.0337 24.9603 14.8527 24.9603 14.8527Z" fill="#DCE5E5"/>
    <path d="M6.98887 11.4146C6.98887 11.4146 7.51744 11.1733 8.28093 11.1733C8.69204 11.1733 9.04442 11.2336 9.04442 11.5956C9.04442 11.8368 8.98569 11.8971 8.98569 11.8971C8.98569 11.8971 8.63331 11.8971 8.45712 11.8971C7.45871 11.8971 6.34283 12.3194 6.34283 13.7067C6.34283 14.7924 7.0476 15.0336 7.45871 15.0336C8.28093 15.0336 8.63331 14.4908 8.69204 14.4908L8.63331 14.9733H9.69045L10.1603 11.6559C10.1603 10.2686 8.98569 10.2083 8.51585 10.2083L6.98887 11.4146ZM9.3968 12.8019C9.3968 12.9829 8.69204 13.9479 7.98728 13.9479C7.6349 13.9479 7.51744 13.6463 7.51744 13.4654C7.51744 13.1638 7.69363 12.7416 8.57458 12.7416C8.75077 12.8019 9.3968 12.8019 9.3968 12.8019Z" fill="#DCE5E5"/>
    <path d="M9.98413 14.8527C9.98413 14.8527 10.3365 14.9734 11.3349 14.9734C11.6286 14.9734 13.0968 15.0337 13.0968 13.4051C13.0968 11.8972 11.6873 12.1988 11.6873 11.5956C11.6873 11.294 11.9222 11.1734 12.3333 11.1734C12.5095 11.1734 13.1556 11.2337 13.1556 11.2337L13.3318 10.148C13.3318 10.148 12.9206 10.0273 12.2159 10.0273C11.3349 10.0273 11.0413 10.3892 11.0413 11.5956C11.0413 12.9829 11.9222 12.8623 11.9222 13.4051C11.9222 13.767 11.5111 13.8273 11.2175 13.8273" fill="#DCE5E5"/>
    <path d="M34.4746 10.7511C34.4746 10.7511 34.0048 10.1479 33.3587 10.1479C32.3016 10.1479 31.9492 11.4749 31.9492 13.0432C31.9492 14.0083 31.8317 15.0336 32.8302 15.0336C33.5349 15.0336 33.946 14.5511 33.946 14.5511L33.8873 14.9733H35.0619L35.9429 9.18286L34.4746 10.7511ZM34.4159 12.3194C34.4159 12.9829 33.8873 13.8273 33.2413 13.8273C32.8302 13.8273 32.5952 13.4654 32.5952 12.8622C32.5952 11.8971 33.0063 11.294 33.5349 11.294C33.946 11.294 34.4159 11.5956 34.4159 12.3194Z" fill="#DCE5E5"/>
    <path d="M2.173 14.913L2.87776 10.5702L2.99522 14.913H3.81745L5.34443 10.5702L4.6984 14.913H5.93173L6.87141 9.12256H5.40316L3.75872 12.6813L3.69999 9.12256H3.05395L1.05713 14.913H2.173Z" fill="#DCE5E5"/>
    <path d="M19.146 14.913H20.3793C20.7317 12.9225 20.7904 11.294 21.6127 11.5956C21.7301 10.8114 21.9063 10.5098 22.0238 10.2083C22.0238 10.2083 21.965 10.2083 21.7889 10.2083C21.2603 10.2083 20.8492 10.9321 20.8492 10.9321L20.9666 10.2686" fill="#DCE5E5"/>
    <path d="M26.0762 11.4146C26.0762 11.4146 26.6048 11.1733 27.3683 11.1733C27.7794 11.1733 28.1318 11.2336 28.1318 11.5956C28.1318 11.8368 28.073 11.8971 28.073 11.8971C28.073 11.8971 27.7207 11.8971 27.5445 11.8971C26.546 11.8971 25.4302 12.3194 25.4302 13.7067C25.4302 14.7924 26.1349 15.0336 26.546 15.0336C27.3683 15.0336 27.7207 14.4908 27.7794 14.4908L27.7207 14.9733H28.7778L29.2476 11.6559C29.2476 10.2686 28.073 10.2083 27.6032 10.2083L26.0762 11.4146ZM28.4841 12.8019C28.4841 12.9829 27.7794 13.9479 27.0746 13.9479C26.7222 13.9479 26.6048 13.6463 26.6048 13.4654C26.6048 13.1638 26.781 12.7416 27.6619 12.7416C27.8968 12.8019 28.4841 12.8019 28.4841 12.8019Z" fill="#DCE5E5"/>
    <path d="M29.0127 14.913H30.246C30.5984 12.9225 30.6571 11.294 31.4794 11.5956C31.5968 10.8114 31.773 10.5098 31.8905 10.2083C31.8905 10.2083 31.8317 10.2083 31.6555 10.2083C31.127 10.2083 30.7159 10.9321 30.7159 10.9321L30.8333 10.2686" fill="#DCE5E5"/>
  </svg>
</template>

<script>
export default {
  name: 'MCIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
