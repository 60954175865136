import { Commit, Dispatch } from 'vuex'
import AuthAPI from '@/api/auth'
import UserAPI from '@/api/users'
import Vue from 'vue'
import { ILoginData, IUserData, IState } from '@/shared/models/auth'

export default {
  state: (): IState => ({
    token: localStorage.getItem('token') || '',
    userData: JSON.parse(<string>localStorage.getItem('user-data')),
    errorMsg: ''
  }),
  mutations: {
    SET_TOKEN(state: IState, token: string): void {
      state.token = token
      localStorage.setItem('token', token)
    },
    STORE_USER_DATA(state: IState, userData: IUserData): void {
      state.userData = userData
      localStorage.setItem('user-data', JSON.stringify(userData))
    },
    SHOW_HELLO_MSG(state: IState): void {
      Vue.toasted.info(`Рады снова Вас видеть, ${state.userData?.first_name || state.userData?.username}`)
    },
    LOGOUT(state: IState): void {
      state.userData = null
      state.token = ''
    },
    SET_ERROR_MSG(state: IState, msg: string): void {
      state.errorMsg = msg
    }
  },
  actions: {
    login({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, data: ILoginData): Promise<unknown> {
      commit('SET_ERROR_MSG', '')

      return AuthAPI.login(data)
        .then(response => commit('SET_TOKEN', response.data.token))
        .then(() => UserAPI.getUserId()) // we have no userID so let`s go get it to retrieve user info by this ID
        .then(response => dispatch('getUserData', response.data.id))
        .then(() => {
          commit('SHOW_HELLO_MSG')
          return Promise.resolve()
        })
        .catch(error => {
          commit('LOGOUT')
          commit('SET_ERROR_MSG', error?.response?.data?.message || '')
          return Promise.reject(error)
        })
    },
    getUserData({ commit }: { commit: Commit }, userId: number): Promise<unknown> {
      return UserAPI.getUserData(userId).then(userData => {
        commit('STORE_USER_DATA', userData.data)
        return Promise.resolve()
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    register({ commit }: { commit: Commit }, data: ILoginData): Promise<unknown> {
      commit('SET_ERROR_MSG', '')
      return UserAPI.createUser(data).then(() => {
        Vue.toasted.success('Регистрация прошла успешно')
        return Promise.resolve()
      }).catch(error => {
        commit('SET_ERROR_MSG', error?.response?.data?.message || '')
        return Promise.reject(error)
      })
    },
    logout({ commit }: { commit: Commit }): void {
      localStorage.removeItem('token')
      localStorage.removeItem('user-data')
      commit('LOGOUT')
    }
  },
  getters: {
    userData(state: IState): IUserData | null {
      return state.userData || null
    },
    userToken(state: IState): string {
      return state.token
    },
    authErrorMsg(state: IState): string {
      return state.errorMsg || ''
    }
  }
}
