export type ModalName = 'loginModal' | 'requestModal'

export interface Data {
  [key: string]: string | number
}

export interface IState {
  isLoginModalVisible: boolean
  isRequestModalVisible: boolean
  data: Data | null
}

export interface ModalData {
  open: boolean;
  modalName: ModalName
  data: Data | null
}

export const MODALS: { [key in ModalName]: keyof Omit<IState, 'data'> } = {
  loginModal: 'isLoginModalVisible',
  requestModal: 'isRequestModalVisible'
}
