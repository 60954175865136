<template>
  <svg width="24" height="22" viewBox="0 0 24 22" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M15 17C11.691 17 9 14.309 9 11C9 7.691 11.691 5 15 5C18.309 5 21 7.691 21 11C21 14.309 18.309 17 15 17ZM15 6C12.243 6 10 8.243 10 11C10 13.757 12.243 16 15 16C17.757 16 20 13.757 20 11C20 8.243 17.757 6 15 6Z" fill="black"/>
    <path d="M23.5 20C23.372 20 23.244 19.951 23.146 19.854L18.53 15.238C18.335 15.043 18.335 14.726 18.53 14.531C18.725 14.336 19.042 14.336 19.237 14.531L23.853 19.147C24.048 19.342 24.048 19.659 23.853 19.854C23.756 19.951 23.628 20 23.5 20Z" fill="black"/>
    <path d="M17.5 1H0.5C0.224 1 0 0.776 0 0.5C0 0.224 0.224 0 0.5 0H17.5C17.776 0 18 0.224 18 0.5C18 0.776 17.776 1 17.5 1Z" fill="black"/>
    <path d="M17.5 22H0.5C0.224 22 0 21.776 0 21.5C0 21.224 0.224 21 0.5 21H17.5C17.776 21 18 21.224 18 21.5C18 21.776 17.776 22 17.5 22Z" fill="black"/>
    <path d="M8.5 6H0.5C0.224 6 0 5.776 0 5.5C0 5.224 0.224 5 0.5 5H8.5C8.776 5 9 5.224 9 5.5C9 5.776 8.776 6 8.5 6Z" fill="black"/>
    <path d="M8.5 17H0.5C0.224 17 0 16.776 0 16.5C0 16.224 0.224 16 0.5 16H8.5C8.776 16 9 16.224 9 16.5C9 16.776 8.776 17 8.5 17Z" fill="black"/>
    <path d="M6.5 11.5H0.5C0.224 11.5 0 11.276 0 11C0 10.724 0.224 10.5 0.5 10.5H6.5C6.776 10.5 7 10.724 7 11C7 11.276 6.776 11.5 6.5 11.5Z" fill="black"/>
  </svg>
</template>

<script>
export default {
  name: 'CatalogIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
