import { Commit } from 'vuex'
import { MainSlide } from '@/shared/models/main-slider'
import API from '@/api/base'

interface IState {
  slides: Array<MainSlide>
}

export default {
  state: (): IState => ({
    slides: []
  }),
  mutations: {
    SET_SLIDES(state: IState, cakes: Array<MainSlide>): void {
      state.slides = cakes
    }
  },
  actions: {
    getSlides({ commit }: { commit: Commit }): void {
      API.getMainSlider().then(response => commit('SET_SLIDES', response.data))
    }
  },
  getters: {
    slides(state: IState): Array<MainSlide> {
      return state.slides || []
    }
  }
}
