<template>
  <svg width="38" height="11" viewBox="0 0 38 11" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.51398 0.0018771C3.85347 -0.000278178 4.86241 -0.0967117 5.28977 1.43395C5.57765 2.46502 6.03623 4.15396 6.6655 6.50078H6.92177C7.59661 4.0266 8.06018 2.33766 8.3125 1.43395C8.74432 -0.112656 9.82387 0.0019074 10.2557 0.0019074L13.5873 0.00191181V11H10.1916V4.51863H9.96393L8.07103 11H5.51624L3.62334 4.51383H3.39564V11H0V0.00191181L3.51398 0.0018771ZM18.4633 0.00191181V6.48808H18.7342L21.0368 1.15455C21.4837 0.0931622 22.4363 0.00191181 22.4363 0.00191181H25.7223V11H22.2558V4.51383H21.9849L19.7275 9.84736C19.2805 10.9039 18.2827 11 18.2827 11H14.9968V0.00191181H18.4633ZM37.7043 5.22828C37.2207 6.68242 35.7022 7.72384 34.021 7.72384H30.3856V11H27.0891V5.22828H37.7043Z" fill="#0F754E"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M34.1807 0.00195312H26.917C27.0899 2.4505 29.0768 4.54662 31.1338 4.54662H37.9341C38.3265 2.51159 36.9756 0.00195312 34.1807 0.00195312Z" fill="url(#paint0_linear)"/>
    <defs>
      <linearGradient id="paint0_linear" x1="38.0008" y1="2.86074" x2="26.917" y2="2.86074" gradientUnits="userSpaceOnUse">
        <stop stop-color="#1F5CD7"/>
        <stop offset="1" stop-color="#02AEFF"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'MirIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
