










import Vue from 'vue'
import Component from 'vue-class-component'
import Socials from '@/components/common/Socials.vue'
import { CONTACTS } from '@/shared/const'
import Phone from '@/components/common/Phone.vue'

@Component({
  components: {
    Socials,
    Phone
  }
})
export default class TopPanel extends Vue {
  data(): Record<string, unknown> {
    return {
      CONTACTS
    }
  }
}
