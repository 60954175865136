export const CONTACTS = {
  ADDRESS: 'г. Тюмень, ул. Н.Федорова, 9',
  PHONE: '+7 (3452) 385-385',
  PHONE_FORMATTED: '+73452385385',
  VK: '',
  INSTAGRAM: 'https://instagram.com/greenhouse_tmn'
}

export const REQUISITES = 'ООО "ОМЕГА" ИНН 7203315552 КПП 720301001 ОГРН 1147232037160 Адрес: 625016 ТЮМЕНСКАЯ ОБЛАСТЬ Г. ТЮМЕНЬ УЛ. НИКОЛАЯ ФЕДОРОВА ДОМ 9'

export const MINCHECK_VALUE = 500

export const PREPAY = {
  FULL: {
    method: 'full_prepayment',
    value: 1
  },
  PART: {
    method: 'partial_prepayment',
    value: 0.5
  }
}

export const PREPAY_METHOD = {
  full_prepayment: 'FULL',
  partial_prepayment: 'PART'
}

export const DELIVERY_SLOTS = {
  first: '10:00 - 12:00',
  second: '12:00 - 14:00',
  third: '14:00 - 16:00',
  fourth: '16:00 - 18:00',
  fifth: '18:00 - 20:00'
}

export const ORDER_STATUS = {
  pending: 'Ожидает оплаты',
  processing: 'Оплачен',
  'on-hold': 'На удержании',
  completed: 'Выполнен',
  cancelled: 'Отменен',
  refunded: 'Возвращен',
  producing: 'Передан в производство',
  failed: 'Не удался'
}

export const CARD_ID = 1446

// купон на скидку только для фирменных тортов и десертов
export const LIMITED_COUPON_ID = 2113
export const SCHOOL_COUPON_ID = 2130

export const POLICY_LINK = '/policy'
