import Vue from 'vue'
import Vuex from 'vuex'
import HeroSlider from '@/store/modules/hero-slider'
import CakeCategories from '@/store/modules/cake-categories'
import CakeFillings from '@/store/modules/cake-fillings'
import Cart from '@/store/modules/cart'
import Auth from '@/store/modules/auth'
import Delivery from '@/store/modules/delivery-methods'
import MobileNav from '@/store/modules/mobile-nav'
import Specials from '@/store/modules/specials'
import Modals from '@/store/modules/modals'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    HeroSlider,
    CakeCategories,
    CakeFillings,
    Cart,
    Auth,
    Delivery,
    MobileNav,
    Specials,
    Modals
  }
})
