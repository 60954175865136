import { API, BASE_API } from '@/api-config'
import { MainSlide } from '@/shared/models/main-slider'
import { CakeFilling } from '@/shared/models/fillings'
import { Special } from '@/shared/models/specials'

export default {
  getMainSlider(): Promise<{ data: Array<MainSlide> }> {
    return API.get(`${BASE_API}/main-slider`)
  },

  getCakeFillings(): Promise<{ data: Array<CakeFilling> }> {
    return API.get(`${BASE_API}/fillings?_embed&per_page=20`)
  },

  getTextPageContent(pageID: number): Promise<{ data: { content: { rendered: string }; title: { rendered: string } } }> {
    return API.get(`${BASE_API}/pages/${pageID}`)
  },

  getSpecials(): Promise<{ data: Array<Special> }> {
    return API.get(`${BASE_API}/specials?_embed&per_page=20`)
  }
}
