import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Toasted from 'vue-toasted'
Vue.use(Toasted, {
  singleton: true,
  keepOnHover: true,
  duration: 3000,
  className: 'beze-toast',
  action: {
    text: 'Закрыть',
    onClick: (e, toastObject) => {
      toastObject.goAway(0)
    }
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
