










import { Component, Vue } from 'vue-property-decorator'

const DEFAULT_TRANSITION = 'fade'
const DEFAULT_TRANSITION_MODE = 'out-in'

@Component
export default class TransitionPage extends Vue {
  prevHeight = '0'
  transitionName: string | null = DEFAULT_TRANSITION
  transitionMode: string | null = DEFAULT_TRANSITION_MODE
  transitionEnterActiveClass: string | null = ''

  created(): void {
    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName || DEFAULT_TRANSITION

      if (transitionName === 'slide') {
        const toDepth = to.path.split('/').length
        const fromDepth = from.path.split('/').length
        transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
      }

      this.transitionMode = DEFAULT_TRANSITION_MODE
      this.transitionEnterActiveClass = `${transitionName}-enter-active`

      if (to.meta.transitionName === 'zoom') {
        this.transitionMode = 'in-out'
        this.transitionEnterActiveClass = 'zoom-enter-active'
        document.body.style.overflow = 'hidden'
      }

      if (from.meta.transitionName === 'zoom') {
        this.transitionMode = null
        this.transitionEnterActiveClass = null
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.body.style.overflow = null
      }

      this.transitionName = transitionName

      next()
    })
  }

  beforeLeave(element: HTMLElement): void {
    this.prevHeight = getComputedStyle(element).height
  }

  enter(element: HTMLElement): void {
    const { height } = getComputedStyle(element)
    element.style.height = this.prevHeight
    setTimeout(() => {
      element.style.height = height
    })
  }

  afterEnter(element: HTMLElement): void {
    element.style.height = 'auto'
  }
}
