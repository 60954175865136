














































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import Logo from '@/components/common/Logo.vue'
import CloseIcon from '@/components/icons/CloseIcon.vue'

@Component({
  components: {
    Logo,
    CloseIcon
  }
})
export default class Navigation extends Vue {
  @Prop({
    required: true,
    default: () => []
  }) navItems!: Array<{ link: string; name: string; isSubItem?: boolean; }>

  @Prop({ default: false }) isFooter!: boolean

  isSubMenuOpen = false

  get isNavOpen(): boolean {
    return this.$store.getters.isMenuOpen
  }

  get catalogNavItems(): Array<{ link: string; name: string; isSubItem?: boolean; }> {
    return this.navItems.filter(item => item.isSubItem)
  }

  get otherNavItems(): Array<{ link: string; name: string; }> {
    return this.navItems.filter(item => !item.isSubItem)
  }

  get isDesktop(): boolean {
    return window.matchMedia('(min-width: 1024px)').matches
  }
}
