
























import Vue from 'vue'
import Component from 'vue-class-component'
import WidgetTitle from '@/components/common/WidgetTitle.vue'
import NaturalIcon from '@/components/icons/NaturalIcon'
import VarietyIcon from '@/components/icons/VarietyIcon'
import TimerIcon from '@/components/icons/TimerIcon'
import PaymentIcon from '@/components/icons/PaymentIcon'

@Component({
  components: {
    WidgetTitle,
    NaturalIcon,
    VarietyIcon,
    TimerIcon,
    PaymentIcon
  }
})
export default class About extends Vue {
  pros = [
    {
      icon: 'NaturalIcon',
      title: 'Натуральные ингредиенты',
      text: 'В составе десертов «Безе» нет вредных консервантов и красителей – это гарант хорошего самочувствия и настроения'
    },
    {
      icon: 'VarietyIcon',
      title: 'Вкусный выбор',
      text: 'Наш ассортимент постоянно расширяется, вы легко найдёте оригинальный десерт на любую тематику, который идеально впишется в ваше мероприятие'
    },
    {
      icon: 'TimerIcon',
      title: 'Максимальная оперативность',
      text: 'Обычный срок изготовления десертов от 1 до 7 дней, а также есть турбо режим для срочных заказов'
    },
    {
      icon: 'PaymentIcon',
      title: 'Бесконтактная оплата',
      text: 'Выбрать нужный десерт и безопасно оплатить его можно прямо на сайте. Мы уверены, что онлайн-платежи экономят ваше время, освобождая его для чего-то более важного и ценного'
    }
  ]
}
