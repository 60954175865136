<template>
  <svg width="82" height="74" viewBox="0 0 82 74" :fill="fill" xmlns="http://www.w3.org/2000/svg">
    <path d="M65.1899 57.5309C64.463 57.0267 63.465 57.208 62.9613 57.9349C62.4732 58.6396 61.92 59.3248 61.3164 59.9716C60.912 60.4055 60.3556 60.9286 59.7502 61.4436C59.0766 62.017 58.9952 63.0277 59.5684 63.7015C59.8852 64.0737 60.3358 64.2649 60.789 64.2649C61.1559 64.2649 61.5247 64.1395 61.8263 63.883C62.5326 63.2817 63.1667 62.6847 63.659 62.156C64.3672 61.3972 65.0184 60.5908 65.5943 59.7593C66.098 59.0323 65.917 58.0345 65.1899 57.5309Z"/>
    <path d="M58.2126 64.7123C57.8368 63.9116 56.8836 63.5669 56.0825 63.9425L56.0323 63.965C55.2231 64.322 54.8566 65.2676 55.2139 66.0769C55.4782 66.6758 56.0647 67.0322 56.6799 67.0322C56.8959 67.0322 57.1155 66.9881 57.3256 66.8954C57.3647 66.8783 57.4034 66.8607 57.4424 66.8426C58.2431 66.4668 58.588 65.5134 58.2126 64.7123Z"/>
    <path d="M77.2153 0H4.78466C2.14625 0 0 2.14657 0 4.78466V49.6687C0 52.3066 2.14625 54.453 4.78466 54.453H38.5113C39.941 60.5021 43.174 64.7863 45.9997 67.531C50.124 71.5378 54.8518 73.6716 56.679 73.6716C58.5061 73.6716 63.2339 71.538 67.3584 67.5312C70.184 64.7863 73.4173 60.5021 74.8468 54.4532H77.2153C79.8536 54.4532 82 52.3068 82 49.6688V4.78466C82 2.14657 79.8536 0 77.2153 0ZM72.3905 47.8895C72.3906 56.3278 68.4955 61.9251 65.2277 65.1348C61.3514 68.9422 57.3752 70.4231 56.679 70.4681C55.9827 70.4231 52.0063 68.9423 48.1301 65.1348C44.8626 61.9251 40.9674 56.3278 40.9674 47.8895V40.0025C46.4816 38.4078 51.6412 36.2254 56.6795 33.3573C61.6043 36.1525 66.9952 38.432 72.3905 40.0015V47.8895ZM78.7969 49.6687C78.7969 50.5406 78.0872 51.2499 77.2153 51.2499H75.4038C75.5265 50.1767 75.5938 49.0575 75.5938 47.8895V38.7882C75.5938 38.0652 75.1095 37.4321 74.412 37.2427C68.5962 35.6634 62.744 33.2024 57.488 30.126C56.9883 29.8335 56.3698 29.8338 55.8698 30.1263C50.4648 33.29 44.929 35.6176 38.9461 37.2427C38.2486 37.4323 37.7643 38.0654 37.7643 38.7882V47.8895C37.7643 49.0575 37.8314 50.1767 37.9542 51.2499H4.78466C3.91261 51.2499 3.20312 50.5404 3.20312 49.6687V22.4215H78.7969V49.6687ZM78.7969 19.2186H3.20312V12.8126H78.7969V19.2186ZM78.7969 9.60936H3.20312V4.78466C3.20312 3.91261 3.91261 3.20312 4.78466 3.20312H77.2153C78.0872 3.20312 78.7969 3.91261 78.7969 4.78466V9.60936Z"/>
    <path d="M19.2182 33.6331C18.2526 33.6331 17.2992 33.8283 16.4168 34.2005C15.5546 33.8352 14.6072 33.6331 13.6135 33.6331C9.6396 33.6331 6.40637 36.866 6.40637 40.8398C6.40637 44.8135 9.63944 48.0468 13.6135 48.0468C14.6072 48.0468 15.5546 47.8447 16.4166 47.4793C17.2992 47.8515 18.2526 48.0468 19.2182 48.0468C23.1923 48.0468 26.4255 44.8135 26.4255 40.8398C26.4255 36.866 23.1923 33.6331 19.2182 33.6331ZM13.6135 44.8437C11.4056 44.8437 9.60949 43.0477 9.60949 40.8398C9.60949 38.632 11.4056 36.8362 13.6135 36.8362C15.8211 36.8362 17.6173 38.632 17.6173 40.8398C17.6173 43.0475 15.8211 44.8437 13.6135 44.8437ZM19.6152 44.8241C20.376 43.682 20.8204 42.3119 20.8204 40.8399C20.8204 39.3679 20.376 37.9981 19.6154 36.856C21.6374 37.0559 23.2223 38.7664 23.2223 40.8399C23.2223 42.9135 21.6374 44.6241 19.6152 44.8241Z"/>
    <path d="M16.2456 26.4258H8.00763C7.12309 26.4258 6.40607 27.1428 6.40607 28.0273C6.40607 28.9117 7.12309 29.6289 8.00763 29.6289H16.2456C17.1301 29.6289 17.8471 28.9116 17.8471 28.0273C17.8471 27.1428 17.1301 26.4258 16.2456 26.4258Z"/>
    <path d="M20.9798 26.4258H20.9039C20.0193 26.4258 19.3023 27.1428 19.3023 28.0273C19.3023 28.9117 20.0193 29.6289 20.9039 29.6289H20.9798C21.8642 29.6289 22.5813 28.9116 22.5813 28.0273C22.5813 27.1428 21.8642 26.4258 20.9798 26.4258Z"/>
    <path d="M65.4701 44.207C64.845 43.5819 63.8311 43.5816 63.2054 44.2068L54.6407 52.7708L50.1527 48.2825C49.5271 47.6576 48.513 47.6572 47.8876 48.2825C47.2622 48.908 47.262 49.9222 47.8876 50.5476L53.5082 56.1684C53.821 56.4812 54.2309 56.6377 54.6407 56.6377C55.0505 56.6377 55.4605 56.4812 55.773 56.1684L65.47 46.4721C66.0955 45.8468 66.0955 44.8327 65.4701 44.207Z"/>
  </svg>
</template>

<script>
export default {
  name: 'PaymentIcon',
  props: {
    fill: {
      type: String,
      default: 'currentColor'
    }
  }
}
</script>

<style></style>
