import { API, BASE_API, WC_API } from '@/api-config'
import { DeliveryType, PromoCode } from '@/shared/models/orders'
import { ModalData } from '@/shared/models/modals'
import { ProductListItem } from '@/shared/models/cakes'

export default {
  createOrder(data: Record<string, unknown>): Promise<{ data: Record<string, any> }> {
    return API.post(`${BASE_API}/create-order`, data)
  },

  getUserOrders(userID: number): Promise<{ data: Array<any> }> {
    return API.get(`${WC_API}/orders?customer=${userID}`)
  },

  getShippingMethods(): Promise<{ data: Array<DeliveryType> }> {
    return API.get(`${WC_API}/shipping/zones/1/methods`)
  },

  checkPromoCode(code: string): Promise<{ data: Array<PromoCode> }> {
    return API.get(`${WC_API}/coupons?code=${code}`)
  },

  createRequestOrder(data: ModalData): Promise<{ data: Record<string, any> }> {
    return API.post(`${BASE_API}/create-request-order`, data)
  },

  checkProductsInCart(ids: number[]): Promise<{ data: Array<ProductListItem> }> {
    return API.get(`${WC_API}/products?status=publish&include=${ids.join(',')}`)
  }
}
